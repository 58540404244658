import {useParams} from "react-router-dom";
import {Container} from "react-bootstrap";
import {useEffect} from "react";

export default function Error404({utilities})
{
    useEffect(() => {
        utilities.setDocumentTitle('404 : Page introuvable');
        utilities.setLocation('404');
    }, [])


    return (
        <Container>
            <div className={`mx-md-5`}>
                <h1>404</h1>
            </div>
        </Container>
    )
}
