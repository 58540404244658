import {Skeleton} from "@mui/material";
import {Container} from "react-bootstrap";

export default function PageLoading(props)
{
    return (
        <Container>
            <div className={`mx-md-5`}>
                <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
            </div>
        </Container>
    )
}