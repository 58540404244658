import {Col, Container, Image, Row} from "react-bootstrap";
import {apiFetch, apiFetchQuery} from "../api";
import {Link} from "react-router-dom";
import {useQuery} from "react-query";
import Error500 from "../pages/Error500";
import {Skeleton} from "@mui/material";

export default function HomeBlockEdito(props) {
    const {
        data,
        error,
        isLoading
    } = useQuery('repoHookBlockEdito', () => apiFetchQuery('get-pages', {
        'hook': 'home-bloc-2',
        'max-result': 1,
        'sigle-result': 1
    }), {
        refetchOnWindowFocus: process.env.NODE_ENV === 'development',
        retry: 1
    });
    if (error) {
        return <Error500/>
    }

    return (
        <>
            {isLoading
                ? (
                    <Container className={`py-5 mt-6`} style={{background: props.bg}}>
                        <div className={`mx-md-5`}>
                            <Row>
                                <Col lg={6}>
                                    <h3 className="text-uppercase"><Skeleton variant={`text`}/></h3>
                                    <div className="mb-1 fs-17">
                                        <Skeleton variant={"text"}/>
                                        <Skeleton variant={"text"}/>
                                        <Skeleton variant={"text"}/>
                                    </div>
                                </Col>
                                <Col lg={6} className="text-end">
                                    <Skeleton variant={"rectangular"} height={300}/>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                ) : (
                    <>
                        {Object.keys(data).length > 0 && (
                            <Container className={`py-5 mt-6`} style={{background: props.bg}}>
                                <div className={`mx-md-5`}>
                                    <Row>
                                        <Col lg={6}>
                                            <h3 className="text-uppercase">{isLoading ?
                                                <Skeleton variant={`text`}/> : data.name}</h3>
                                            <div className="mb-1 fs-17"
                                                 dangerouslySetInnerHTML={{__html: data.summary}}></div>
                                            <Link to={data.link}>Lire la suite ></Link>
                                        </Col>
                                        <Col lg={6} className="text-end">
                                            {data.logo !== undefined && data.logo !== "" &&
                                                <Image fluid={1} src={data.logo} alt={data.name}/>
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            </Container>
                        )}
                    </>
                )
            }

        </>
    )
}