import {Field, Form, Formik} from "formik";
import {apiFetchQuery} from "../api";
import {useState} from "react";
import {Alert, Snackbar} from "@mui/material";
import {Col, Row, Button} from "react-bootstrap";

export default function FormNewsletter() {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const handleCloseSnackbar = (event) => {
        setOpenSnackbar(false);
    };

    return (
        <div className={`mx-md-5`}>
            <Formik
                initialValues={{email: ""}}
                validate={values => {
                    const errors = {};
                    if (!values.email) {
                        errors.email = 'Merci de renseigner votre adresse email.';
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                        errors.email = 'Veuillez saisir une adresse email valide.';
                    }
                    return errors;
                }}
                onSubmit={async (values, {resetForm}) => {
                    apiFetchQuery('subscribe-newsletter', {}, 'POST', values);
                    resetForm();
                    setOpenSnackbar(true);
                }}
            >
                {({isSubmitting, values, errors, submitCount}) => (
                    <div className={`bloc-newsletter`}>
                        <h3 className={`text-uppercase`}>ABONNEZ-VOUS À NOTRE NEWSLETTER</h3>
                        <Form>
                            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                                <Alert onClose={handleCloseSnackbar} severity="success" sx={{width: '100%'}}>
                                    Votre demande a bien été enregistrée
                                </Alert>
                            </Snackbar>
                            <div className={"form-group form-group-sm mb-3"}>
                                <Field type={"text"} name={"email"} className={"form-control "}
                                       placeholder={`Adresse Email`}/>
                                <div className={`float-end mt-2`}>
                                    <Button type={`submit`} variant=""
                                            className={"btn btn-sm bg-dark border-0 rounded-0"}>Envoyer</Button>
                                </div>

                                {submitCount > 0 && errors.email &&
                                    <span className={`text-danger`}>* {errors.email}</span>}
                            </div>
                        </Form>
                    </div>
                )}
            </Formik>
        </div>
    );
}