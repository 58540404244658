import React, {useEffect} from "react";
import {Link, useParams} from "react-router-dom";
import {toolsGetId, apiFetchQuery} from "../api";
import {useQuery} from "react-query";
import Error500 from "./Error500";
import Error404 from "./Error404";
import {Col, Container, Image, Row} from "react-bootstrap";
import {Skeleton} from "@mui/material";
import {useEdition} from "../contexts/EditionContext";

export default function Place({utilities}) {
    useEffect(() => {
        utilities.setLocation('place');
        utilities.setBreadcrumbItems([
            {'link': '#', name: 'Contenu'},
        ]);
    }, []);


    const $params = useParams();
    const id = toolsGetId($params.id);
    const {contextEditionData} = useEdition();
    const {
        data,
        error,
        isLoading
    } = useQuery(['repoPagePlace', [contextEditionData, id]], () => apiFetchQuery('get-place', {
        id: id,
        edition: contextEditionData.currentEdition.id
    }), {
        refetchOnWindowFocus: process.env.NODE_ENV === 'development',
        retry: 1,
        onSuccess: (data) => {
            utilities.setDocumentTitle(data.place.name);
            utilities.setBreadcrumbItems([
                {'link': '#', name: data.place.cmsPagePlaces.hook},
                {'link': data.place.cmsPagePlaces.link, name: data.place.cmsPagePlaces.name},
                {'link': data.place.link, name: data.place.name, 'isActive': 1},
            ]);
        }
    });

    if (error) {
        return <Error500 utilities={utilities}/>
    }

    if (!isLoading && data.place.id === undefined) {
        return <Error404 utilities={utilities}/>;
    }

    return (
        <Container>
            <div className={`sub-container`}>
                <h1>{isLoading ? <Skeleton variant={"text"}/> : data.name}</h1>
                {isLoading
                    ? (
                        <Row>
                            <Col sm={4}><Skeleton variant={"rectangular"} height={300}/></Col>
                            <Col sm={8}>
                                <Skeleton variant={"text"}/>
                                <Skeleton variant={"text"}/>
                                <Skeleton variant={"text"}/>
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col sm={12} className="pt-2">
                                <h1 className="fs-20 text-uppercase">{data.place.name}</h1>
                                <div className="fs-16 lh-sm mb-3 fw-semi-bold">{data.place.address}
                                    <br/>{data.place.postalCode} {data.place.city}</div>
                                {data.place.description &&
                                    <div dangerouslySetInnerHTML={{__html: data.place.description}}></div>}

                                {data.place.events.length > 0 &&
                                    <h3 className="fs-18 mb-1">Les événements pendant le Festival</h3>}

                                <ul>
                                    {data.place.events.map(event => (
                                        <li key={event.id} className="mb-2"><Link to={event.link}
                                                                                  className="fst-normal">{event.date} - {event.name}</Link>
                                        </li>
                                    ))}
                                </ul>

                                {data.place.dedications.length > 0 &&
                                    <>
                                        <h3 className="fs-18 mb-1">Les dédicaces</h3>
                                        <table className={`table table-bordered`}>
                                            <tbody>
                                            <tr>
                                                <th className={`fw-bold`}>AUTEURS</th>
                                                <th></th>
                                                <th></th>
                                            </tr>

                                            {data.place.dedications.map(dedication => (
                                                <tr key={dedication.id}>
                                                    <td>{dedication.author.name}</td>
                                                    <td>{dedication.day}</td>
                                                    <td>{dedication.schedule}</td>
                                                </tr>
                                            ))}
                                            </tbody>

                                        </table>
                                    </>
                                }
                            </Col>
                            <Col sm={12} className="fs-4">
                                {data.place.photo &&
                                    <div className=" logo"><Image src={data.place.photo} fluid={1}/></div>
                                }
                            </Col>
                        </Row>
                    )
                }
            </div>
        </Container>
    );
}
