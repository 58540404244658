import {Button, Col, Image, Row} from "react-bootstrap";
import {apiFetchQuery} from "../api";
import {Field, Form, Formik} from "formik";
import {useState} from "react";

import {Alert, Snackbar} from "@mui/material";

import "../assets/styles/Animation.css";

export default function Accreditation({utilities, data}) {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const handleCloseSnackbar = (event) => {
        setOpenSnackbar(false);
    };

    return (
        <>
            <Formik
                initialValues={{
                    type: "Presse",
                    firstname: "",
                    lastname: "",
                    press: "",
                    pressCard: "",
                    activity: "",
                    address: "",
                    postalCode: "",
                    city: "",
                    email: "",
                    mobil: "",
                    comment: ""
                }}
                validate={values => {
                    const errors = {};
                    if (!values.email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                        errors.email = 'Merci de renseigner votre adresse email.';
                    }
                    if (!values.firstname) {
                        errors.firstname = 'Merci de renseigner votre prénom.';
                    }
                    if (!values.lastname) {
                        errors.lastname = 'Merci de renseigner votre nom.';
                    }

                    if (values.type !== 'Professionnel') {
                        if (!values.press) {
                            errors.press = 'Merci de renseigner le média représenté.';
                        }
                        // if (!values.pressCard) {
                        //     errors.pressCard = 'Merci de renseigner votre numéro de carte de presse.';
                        // }
                    } else if (values.type === 'Professionnel' && !values.activity) {
                        errors.activity = 'Merci de renseigner votre activité.';
                    }
                    // if (!values.address) {
                    //     errors.address = 'Merci de renseigner votre adresse.';
                    // }
                    // if (!values.postalCode) {
                    //     errors.postalCode = 'Merci de renseigner votre code postal.';
                    // }
                    // if (!values.city) {
                    //     errors.city = 'Merci de renseigner votre ville.';
                    // }
                    if (!values.mobil) {
                        errors.mobil = 'Merci de renseigner votre numéro de mobile.';
                    }

                    return errors;
                }}
                onSubmit={async (values, {resetForm}) => {
                    apiFetchQuery('send-form-accreditation', {}, 'POST', values);
                    resetForm();
                    setOpenSnackbar(true);
                }}
            >
                {({isSubmitting, values, errors, submitCount}) => (
                    <Form>
                        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                            <Alert onClose={handleCloseSnackbar} severity="success" sx={{width: '100%'}}>
                                Votre message a bien été envoyé
                            </Alert>
                        </Snackbar>
                        <div className={"form-group form-group-sm mb-3"}>
                            <label
                                className={submitCount > 0 && errors.type && `shake-horizontal`}>{submitCount > 0 && errors.type ? (
                                <span className={`text-danger`}>* {errors.type}</span>) : (<>Type <span
                                className={`text-danger`}>*</span></>)}</label>
                            <Field as={`select`} name={"type"} className={"form-control"}>
                                <option value={`Presse`}>Presse</option>
                                <option value={`Photographe`}>Photographe</option>
                                <option value={`Professionnel`}>Professionnel</option>
                            </Field>
                        </div>
                        <Row>
                            <Col>
                                <div className={"form-group form-group-sm mb-3"}>
                                    <label
                                        className={submitCount > 0 && errors.firstname && `shake-horizontal`}>{submitCount > 0 && errors.firstname ? (
                                        <span className={`text-danger`}>* {errors.firstname}</span>) : (<>Prénom <span
                                        className={`text-danger`}>*</span></>)}</label>
                                    <Field type={"text"} name={"firstname"} className={"form-control"}/>
                                </div>
                            </Col>
                            <Col>
                                <div className={"form-group form-group-sm mb-3"}>
                                    <label
                                        className={submitCount > 0 && errors.lastname && `shake-horizontal`}>{submitCount > 0 && errors.lastname ? (
                                        <span className={`text-danger`}>* {errors.lastname}</span>) : (<>Nom <span
                                        className={`text-danger`}>*</span></>)}</label>
                                    <Field type={"text"} name={"lastname"} className={"form-control"}/>
                                </div>
                            </Col>
                        </Row>

                        <div
                            className={"form-group form-group-sm mb-3 " + (values.type === 'Professionnel' ? 'd-none' : '')}>
                            <label
                                className={submitCount > 0 && errors.press && `shake-horizontal`}>{submitCount > 0 && errors.press ? (
                                <span className={`text-danger`}>* {errors.press}</span>) : (<>Média représenté <span
                                className={`text-danger`}>*</span></>)}</label>
                            <Field type={"text"} name={"press"} className={"form-control"}/>
                        </div>
                        <div
                            className={"form-group form-group-sm mb-3 " + (values.type === 'Professionnel' ? 'd-none' : '')}>
                            <label
                                className={submitCount > 0 && errors.pressCard && `shake-horizontal`}>{submitCount > 0 && errors.pressCard ? (
                                <span className={`text-danger`}>* {errors.pressCard}</span>) : (<>Numéro de carte de
                                presse <span className={`text-danger`}></span></>)}</label>
                            <Field type={"text"} name={"pressCard"} className={"form-control"}/>
                        </div>
                        <div
                            className={"form-group form-group-sm mb-3 " + (values.type !== 'Professionnel' ? 'd-none' : '')}>
                            <label>{submitCount > 0 && errors.activity ? (
                                <span className={`text-danger`}>* {errors.activity}</span>) : (<>Activité <span
                                className={`text-danger`}>*</span></>)}</label>
                            <Field type={"text"} name={"activity"} className={"form-control"}/>
                        </div>

                        <div className={"form-group form-group-sm mb-3 "}>
                            <label
                                className={submitCount > 0 && errors.address && `shake-horizontal`}>{submitCount > 0 && errors.address ? (
                                <span className={`text-danger`}>* {errors.address}</span>) : (<>Adresse <span
                                className={`text-danger`}></span></>)}</label>
                            <Field type={"text"} name={"address"} className={"form-control"}/>
                        </div>

                        <Row>
                            <Col>
                                <div className={"form-group form-group-sm mb-3"}>
                                    <label
                                        className={submitCount > 0 && errors.postalCode && `shake-horizontal`}>{submitCount > 0 && errors.postalCode ? (
                                        <span className={`text-danger`}>* {errors.postalCode}</span>) : (<>Code
                                        postal <span className={`text-danger`}></span></>)}</label>
                                    <Field type={"text"} name={"postalCode"} className={"form-control"}/>
                                </div>
                            </Col>
                            <Col>
                                <div className={"form-group form-group-sm mb-3"}>
                                    <label
                                        className={submitCount > 0 && errors.city && `shake-horizontal`}>{submitCount > 0 && errors.city ? (
                                        <span className={`text-danger`}>* {errors.city}</span>) : (<>Ville <span
                                        className={`text-danger`}></span></>)}</label>
                                    <Field type={"text"} name={"city"} className={"form-control"}/>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <div className={"form-group form-group-sm mb-3"}>
                                    <label
                                        className={submitCount > 0 && errors.email && `shake-horizontal`}>{submitCount > 0 && errors.email ? (
                                        <span className={`text-danger`}>* {errors.email}</span>) : (<>Adresse mail <span
                                        className={`text-danger`}>*</span></>)}</label>
                                    <Field type={"text"} name={"email"} className={"form-control"}/>
                                </div>
                            </Col>
                            <Col>
                                <div className={"form-group form-group-sm mb-3"}>
                                    <label
                                        className={submitCount > 0 && errors.mobil && `shake-horizontal`}>{submitCount > 0 && errors.mobil ? (
                                        <span className={`text-danger`}>* {errors.mobil}</span>) : (<>Mobile <span
                                        className={`text-danger`}>*</span></>)}</label>
                                    <Field type={"text"} name={"mobil"} className={"form-control"}/>
                                </div>
                            </Col>
                        </Row>


                        <div className={"form-group form-group-sm mb-3"}>
                            <label
                                className={submitCount > 0 && errors.comment && `shake-horizontal`}>{submitCount > 0 && errors.comment ? (
                                <span
                                    className={`text-danger`}>* {errors.comment}</span>) : (<>Commentaire </>)}</label>
                            <Field as={"textarea"} name={"comment"} className={"form-control"}/>
                        </div>

                        <Button type={`submit`} variant=""
                                className={"btn btn-sm bg-dark border-0 rounded-0"}>Envoyer</Button>
                    </Form>
                )}
            </Formik>
            <hr/>
            <Row>
                {data.logo !== undefined && data.logo !== "" && <Col><Image src={data.logo} fluid={1}/></Col>}
                <Col>
                    <div dangerouslySetInnerHTML={{__html: data.content}}></div>
                </Col>
            </Row>
        </>
    )
}
