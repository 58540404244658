import {Skeleton} from "@mui/material";

export default function SkeletonPageCard(props)
{
    return (
        <div>
            <Skeleton variant={"rectangular"} height={233}/>
            <h4 className="mt-3 mb-1"><Skeleton variant={"text"} /></h4>
            <div className="mb-1" >
                <Skeleton variant={"text"}/>
                <Skeleton variant={"text"}/>
            </div>
        </div>
    );
}