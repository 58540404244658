import {Col, Container, Image, Row} from "react-bootstrap";
import {apiFetch, apiFetchQuery} from "../api";
import {Link} from "react-router-dom";
import {useQuery} from "react-query";
import Error500 from "../pages/Error500";
import {Skeleton} from "@mui/material";
import {useMediaQuery} from "react-responsive";

export default function HomeBlockVideo(props) {
    const isTabletOrMobile = useMediaQuery({maxWidth: 992});
    const {
        data,
        error,
        isLoading
    } = useQuery('repoHookBlockVideo', () => apiFetchQuery('get-homepage-video', {
        'hook': 'home-bloc-video',
        'max-result': 1,
        'sigle-result': 1,
        'edition': 2022
    }),{
        refetchOnWindowFocus: process.env.NODE_ENV === 'development',
        retry: 1
    });
    if (error) {
        return (<></>)
    }

    return (
        <>
            {isLoading
                ? (
                    <Container className={`py-5 mt-6`} style={{background: props.bg}}>
                        <div className={`mx-md-5`}>
                            <Row>
                                <Col lg={4}>
                                    <h2>Festival 2022</h2>
                                    <h3 className="text-uppercase"><Skeleton variant={`text`}/></h3>
                                    <div className="mb-1 fs-17">
                                        <Skeleton variant={"text"}/>
                                        <Skeleton variant={"text"}/>
                                        <Skeleton variant={"text"}/>
                                    </div>
                                </Col>
                                <Col lg={8} className="text-end">
                                    <Skeleton variant={"rectangular"} height={300}/>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                ) : (
                    <>
                        {data.video !== null && (
                            <Container className={`py-5 mt-6`} style={{background: props.bg}}>
                                <div className={`mx-md-5`}>
                                    <Row>
                                        <Col lg={6}>
                                            <h2 className="text-uppercase text-white">Festival 2022</h2>
                                            <h3 className="text-uppercase text-white">{isLoading ?
                                                <Skeleton variant={`text`}/> : data.title}</h3>
                                            <div className="mb-1 fs-17 text-white">
                                                {data.description}
                                            </div>
                                            {isTabletOrMobile && <br/>}
                                        </Col>
                                        <Col lg={6} className="text-end">
                                            <div>
                                                <iframe width="100%" height="315"
                                                        src={data.video}
                                                        title="YouTube video player" frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen></iframe>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Container>
                        )}
                    </>
                )
            }

        </>
    )
}