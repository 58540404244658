import React, {useEffect, useState} from "react";
import {apiFetchQuery} from "../api";
import {Col, Container, Row} from "react-bootstrap";
import ParticipantCard from "../components/ParticipantCard";
import {useQuery} from "react-query";
import Error500 from "./Error500";
import SkeletonParticipantCard from "../skeletons/SkeletonParticipantCard";
import {useEdition} from "../contexts/EditionContext";

export default function Participants({role, utilities}) {
    const [pageTitle, setPageTitle] = useState('');
    useEffect(() => {
        let title = role === 'AUT' ? `Auteurs d'Amérique` : (role === 'INT' ? `Auteurs d'Europe` : `Autres invités`);
        setPageTitle(title)
        utilities.setDocumentTitle(title);
        utilities.setLocation('participants');
        utilities.setBreadcrumbItems([
            {'link': '#', 'name': 'Invités'},
            {
                'link': (role === 'AUT' ? '/les-auteurs' : (role === 'INT' ? '/les-invites' : '/les-artistes')),
                'name': title
            },
        ]);
    }, [role]);


    let background = '';
    const getRowClasses = () => {
        background = (background === 'bg-yellow' ? 'bg-white' : (background === 'bg-white' ? 'bg-pink text-white' : 'bg-yellow'));
        return background + ` py-5 mb-4`;
    }

    const {contextEditionData} = useEdition();
    const {
        data,
        error,
        isLoading
    } = useQuery(['repoAuthorsList', [contextEditionData, role]], () => apiFetchQuery('get-authors', {
        'edition': contextEditionData.currentEdition !== undefined ? contextEditionData.currentEdition.id : null,
        role: role,
        'chunk': 4
    }), {
        refetchOnWindowFocus: process.env.NODE_ENV === 'development',
        retry: 1
    });
    if (error) {
        return <Error500 utilities={utilities}/>
    }

    return (
        <div>
            <Container>
                <h1 className={`mx-md-5`}>{pageTitle} {contextEditionData.currentEdition && contextEditionData.currentEdition.id !== contextEditionData.lastEdition.id && contextEditionData.currentEdition.name}</h1>
            </Container>
            {isLoading
                ? (
                    <div>
                        <Container className={`bg-yellow`}>
                            <div className={`mx-md-5`}>
                                <Row className={`gy-3`}>
                                    <Col xs={6} sm={3}><SkeletonParticipantCard/></Col>
                                    <Col xs={6} sm={3}><SkeletonParticipantCard/></Col>
                                    <Col xs={6} sm={3}><SkeletonParticipantCard/></Col>
                                    <Col xs={6} sm={3}><SkeletonParticipantCard/></Col>
                                </Row>
                            </div>
                        </Container>
                        <Container>
                            <div className={`mx-md-5`}>
                                <Row className={`gy-3`}>
                                    <Col xs={6} sm={3}><SkeletonParticipantCard/></Col>
                                    <Col xs={6} sm={3}><SkeletonParticipantCard/></Col>
                                    <Col xs={6} sm={3}><SkeletonParticipantCard/></Col>
                                    <Col xs={6} sm={3}><SkeletonParticipantCard/></Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                ) : (
                    <div className={`mt-5`}>
                        <Container>
                            {data.length === 0 && <p className={`fs-16 text-center`}>À venir: les invités de l’édition 2024</p>}

                        </Container>
                        {data.map(item => (
                            <Container key={item.key} className={getRowClasses()}>
                                <div className={`mx-md-5`}>
                                    <Row className={`gy-3`}>
                                        {item.participants.map(participant => (
                                            <Col xs={6} sm={3} key={participant.id}><ParticipantCard
                                                participant={participant}/></Col>
                                        ))}
                                    </Row>
                                </div>
                            </Container>
                        ))}

                    </div>
                )
            }

        </div>
    );
}
