import {createContext, useContext, useEffect, useMemo, useState} from "react";
import {toolsSetLocalStorage, toolsGetLocalStorage, apiFetch} from "../api";

export const EditionContext = createContext({
    contextEditionData: {},
    setContextEditionData: () => {},
});

export const EditionProvider = ({children}) => {

    const savedContextEdition = toolsGetLocalStorage('contextEditionData');

    const [isLoaded, setIsLoaded] = useState(0);
    const [error, setError] = useState(0);
    const [contextEditionData, setContextEditionData] = useState(savedContextEdition ? JSON.parse(savedContextEdition) : {});

    useEffect(() => {
        if(contextEditionData.currentEdition === undefined)
        {
            apiFetch('get-last-edition', {}, setIsLoaded, setContextEditionData, setError);
        }
        toolsSetLocalStorage('contextEditionData', JSON.stringify(contextEditionData), 7200); // 2 heures
    }, [contextEditionData]);


    const values = useMemo(
        () => ({contextEditionData, setContextEditionData}),
        [contextEditionData]
    );

    return (
        <EditionContext.Provider value={values}>{children}</EditionContext.Provider>
    );
}
export const useEdition = () => useContext(EditionContext);