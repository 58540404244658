export const apiFetch = (request, parameters, setIsLoaded, setResult, setError) => {
    let baseUrl = window.location.protocol + '//' + window.location.hostname;
    if (window.location.hostname === 'localhost'){
        baseUrl = 'https://www.festival-america.com';
    }
    let url = new URL(baseUrl + "/api/" + request);
    url.search = new URLSearchParams(parameters).toString();

    fetch(url, {mode: 'cors'})
        .then(res => res.json())
        .then(
            (result) => {
                setResult(result);
                setIsLoaded(true);
            },
            // Remarque : il faut gérer les erreurs ici plutôt que dans
            // un bloc catch() afin que nous n’avalions pas les exceptions
            // dues à de véritables bugs dans les composants.
            (error) => {
                setIsLoaded(true);
                setError(error);
            }
        )
    ;
}

export const apiFetchQuery = (request, parameters, method, body) => {
    let baseUrl = window.location.protocol + '//' + window.location.hostname;
    if (window.location.hostname === 'localhost'){
        baseUrl = 'https://www.festival-america.com';
    }
    let url = new URL(baseUrl + "/api/" + request);
    url.search = new URLSearchParams(parameters).toString();

    let postData = null;
    if (body !== undefined) {
        postData = JSON.stringify(body);
    }

    const result = fetch(url, {
            mode: 'cors',
            method: method !== undefined ? method : 'GET',
            'Content-Type': 'application/json',
            body: postData
        })
            .then(res => res.json())
    ;

    return result;
}

export const toolsGetId = ($string) => {
    if ($string !== undefined && $string !== "") {
        let $data = $string.split('-');
        return $data[0];
    }
    return null;

}

export const toolsSetLocalStorage = (key, data, duration) => {
    const now = new Date()

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item =
        {
            value: data,
            expiry: now.getTime() + duration,
        }

    localStorage.setItem(key, JSON.stringify(item))
}

export const toolsGetLocalStorage = (key) => {
    const data = localStorage.getItem(key)

    // if the item doesn't exist, return null
    if (!data) {
        return null
    }

    const item = JSON.parse(data)
    const now = new Date()

    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key)

        return null
    }

    return item.value
}