import React, { useRef, useEffect, useState } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import ReactDOM from "react-dom";

const mapRender = (status) => {
    if (status === Status.LOADING) return <p>Loading...</p>;
    if (status === Status.FAILURE) return <p>Error...</p>;
    return null;
};

const MapDom = ({ id, children, ...props }) => {
    const ref = useRef();
    const [map, setMap] = useState(null);
    const [customControls, setCustomControls] = useState([]);

    useEffect(() => {
        const customs = React.Children.map(children, (child) => {
            if (
                React.isValidElement(child) &&
                child.type.displayName === "CustomControl"
            ) {
                return child;
            }
        });
        setCustomControls(customs);
    }, [children]);

    useEffect(() => {
        if (map && customControls.length) {
            map.controls[window.google.maps.ControlPosition.TOP_CENTER] = [];
            customControls.map((control) => {
                const container = window.document.createElement("div");
                ReactDOM.render(control, container);
                map.controls[
                    window.google.maps.ControlPosition[control.props.position]
                    ].push(container);

                return null;
            });
        }
    }, [map, customControls]);

    useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, props));
        }
    }, [ref, map, props]);

    return (
        <>
            <div ref={ref} id={id} ></div>
            {React.Children.map(children, (child) => {
                if (
                    React.isValidElement(child) &&
                    child.type.displayName !== "CustomControl1"
                ) {
                    return React.cloneElement(child, { map });
                }
            })}
        </>
    );
};
MapDom.displayName = "Map";

const Map = ({ apiKey, render = mapRender, children, ...props }) => {
    return (
        <Wrapper apiKey={apiKey} render={render}>
            <MapDom {...props}>{children}</MapDom>
        </Wrapper>
    );
};

export default Map;
