import {Button, Modal} from "react-bootstrap";
import {useState} from "react";
import {Formik, Form, Field, ErrorMessage} from 'formik';
import {apiFetch, apiFetchQuery} from "../api";


export default function ModalEventAgenda(props) {
    const lastEvent = props.events.slice(-1)[0];

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.events.length === 1 ? (
                        <div>Ajouter l'événement <i>{lastEvent.name}</i> à votre agenda</div>
                    ) : `Ajouter un ou plusieurs événements à votre agenda`}
                </Modal.Title>
            </Modal.Header>

            <Formik
                initialValues={{email: "", "events": [], 'event': lastEvent.id.toString()}}
                validate={values => {
                    const errors = {};
                    if (!values.email) {
                        errors.email = 'Merci de renseigner votre adresse email.';
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                        errors.email = 'Veuillez saisir une adresse email valide.';
                    }
                    if (props.events.length > 1 && values.events.length === 0) {
                        errors.events = 'Veuillez choisir votre événements.';
                    }
                    return errors;
                }}
                onSubmit={async (values) => {
                    let results = apiFetchQuery('send-mail-events-calendar', values);
                    props.onHide();
                }}
            >
                {({isSubmitting, values, errors}) => (
                    <Form>
                        <Modal.Body>
                            {props.events.length === 1 ? (
                                <div className={`d-none`}>
                                    <Field type={`text`} name={"event"}/>
                                </div>
                            ) : (
                                <>
                                    <p className="mb-1">Choisissez un ou plusieurs événements</p>
                                    {props.events.map(event => (
                                        <div key={event.id} role="group" aria-labelledby="checkbox-group">
                                            {!event.isOutOfDate &&
                                                <div className={`form-check`}>
                                                    <label>
                                                        <Field className={`form-check-input`} type={"checkbox"}
                                                               name={"events"}
                                                               value={event.id.toString()}/> {event.name}
                                                    </label>
                                                </div>
                                            }
                                        </div>
                                    ))}
                                </>
                            )}

                            <div className={"form-group form-group-sm"}>
                                <Field type={"email"} name={"email"}
                                       placeholder={"Merci de renseigner votre adresse email"}
                                       className={"form-control"}
                                />
                            </div>
                            <div className={`alert text-danger`}>
                                {errors.email && (<div>* {errors.email}</div>)}
                                {errors.events && (<div>* {errors.events}</div>)}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-dark" className={"btn btn-sm fw-normal"}
                                    onClick={props.onHide}>Annuler</Button>
                            <Button type={`submit`} variant="outline-primary"
                                    className={"btn btn-sm bg-dark rounded-0 border-0"}>Envoyer</Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
}