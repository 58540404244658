import React, {cloneElement, isValidElement, useEffect, useRef, useState} from "react";
import {useQuery} from "react-query";
import {apiFetchQuery} from "../api";
import {useEdition} from "../contexts/EditionContext";
import {Col, Image, Row} from "react-bootstrap";
import {Skeleton} from "@mui/material";
import {Link} from "react-router-dom";
import {Wrapper, Status} from "@googlemaps/react-wrapper";
import Map from "../maps/maps";
import Marker from "../maps/marker";
import InfoWindow from "../maps/infoWindow";

export default function Places({utilities, csmData}) {

    useEffect(() => {
        utilities.setLocation('places');
    }, [])

    const ref = useRef(null);
    const [map, setMap] = useState();
    const [outsideTheWalls, setOutsideTheWalls] = useState([]);
    useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {}));
        }
    }, [ref, map])


    const {contextEditionData} = useEdition();
    const {
        data,
        error,
        isLoading
    } = useQuery(['repoPagePlaces', contextEditionData], () => apiFetchQuery('get-places', {'edition': contextEditionData.currentEdition.id}), {
        refetchOnWindowFocus: process.env.NODE_ENV === 'development',
        retry: 1,
        onSuccess: (data) => {
            let outside = [];
            data.places.filter(place => place.isOutsideTheWalls).map((place) => {
                outside = [...outside, place];
            });
            setOutsideTheWalls(outside);
        }
    });

    return (
        <>
            <Row>
                {csmData.logo !== undefined && csmData.logo !== "" && <Col><Image src={csmData.logo} fluid={1}/></Col>}
                <Col>
                    <div dangerouslySetInnerHTML={{__html: csmData.content}}></div>
                </Col>
            </Row>

            {isLoading ? (
                <>
                    <ul className={`list-group list-group-flush my-3 fs-16`}>
                        <li><Skeleton/></li>
                        <li><Skeleton/></li>
                        <li><Skeleton/></li>
                    </ul>
                    <Skeleton variant={"rectangular"} height={300}/>
                </>

            ) : (
                <>
                    <ul className={`list-group list-group-flush my-3 fs-16 ps-3`}>
                        {data.places.filter(place => !place.isOutsideTheWalls).map(place => (
                            <li key={place.id}>
                                <Link to={place.link} className={`fst-normal fw-normal`}>{place.name}</Link>
                            </li>

                        ))}
                    </ul>

                    {outsideTheWalls &&
                        <>
                            <div className={`fw-bold`}>HORS LES MURS</div>
                            <ul className={`list-group list-group-flush my-3 fs-16 ps-3`}>
                                {outsideTheWalls.map(place => (
                                    <li key={place.id}>
                                        <Link to={place.link} className={`fst-normal fw-normal`}>{place.name}</Link>
                                    </li>
                                ))}
                            </ul>
                        </>
                    }

                    <Map id={`maps`} apiKey={"AIzaSyAOrzJWEXdhw7R3i0ZIsMIEVLtkzi8l2XA"}
                         center={{lat: 48.84689718445516, lng: 2.436673210892696}} zoom={14}>
                        {data.places.map(place => (
                            <Marker key={place.id} position={{lat: place.coordinates.lat, lng: place.coordinates.lng}}>
                                <InfoWindow content={(
                                    <div style={{'max-width': '300px'}}>
                                        <div className={`fs-16 mb-1`}>{place.name}</div>
                                        <div>{place.address}</div>
                                        {place.photo &&
                                            <div className={`mt-2`}><Image src={place.photo} fluid={1}/></div>}
                                    </div>)} title={place.name}
                                />
                            </Marker>

                        ))}
                    </Map>
                </>
            )}

        </>
    );
}
