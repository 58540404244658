import {Link} from "react-router-dom";
import {Image} from "react-bootstrap";
import defaultEvent from "../assets/images/default-event.jpg";

export default function EventCard(props) {
    const event = props.event;

    return (
        <div className={`event`}>
            <div className="image">
                <Link to={event.link}>
                    <Image src={event.logo ? event.logo : defaultEvent}
                           fluid={1}
                           className="max-height-258"
                           alt={event.name}/>
                </Link>
            </div>
            <h4 className="mt-3 mb-1">{event.date && event.timeRange + ' - '}{event.name}</h4>
            {/*
            <p>
                <a href={event.link}>Voir l'événement ></a>
            </p>
            */}
        </div>
    );
}