import React, {useEffect, useRef, useState} from "react";
import {useEdition} from "../contexts/EditionContext";
import {useQuery} from "react-query";
import {apiFetchQuery} from "../api";
import {Col, Image, Row} from "react-bootstrap";
import {Skeleton} from "@mui/material";
import {Link} from "react-router-dom";
import Map from "../maps/maps";
import Marker from "../maps/marker";
import InfoWindow from "../maps/infoWindow";

export default function Residences({utilities, cmsData}) {

    useEffect(() => {
        utilities.setLocation('residences');
    }, [])

    const {contextEditionData} = useEdition();
    const {
        data,
        error,
        isLoading
    } = useQuery('repoResidences', () => apiFetchQuery('get-pages', {'override': 'residence', 'order-by': 'page.name', 'order-by-dir': 'DESC'}), {
        refetchOnWindowFocus: process.env.NODE_ENV === 'development',
        retry: 1,
    });

    return (
        <>
            <Row>
                <Col>
                    <div dangerouslySetInnerHTML={{__html: cmsData.content}}></div>
                </Col>
            </Row>

            {!isLoading && (
                <Row>
                    {data.map(residence => (
                        <Col key={residence.id} xs={6} lg={4}>
                            <Link to={residence.link}>
                                {residence.logo !== ''
                                    ? <Image src={residence.logo}/>
                                    : <Skeleton height={200}></Skeleton>
                                }
                                <h4>{residence.name}</h4>
                            </Link>
                        </Col>
                    ))}
                </Row>
            )}

        </>
    );
}