import {Col, Image} from "react-bootstrap";
import {Link, Router} from "react-router-dom";

export default function PageCard(props) {
    const page = props.item;
    return (
        <Col sm={4}>
            {page.logo !== undefined && page.logo !== "" &&
                <Image alt={page.name} fluid={1} src={page.logo}/>
            }
            <h4 className="mt-3 mb-1">{page.name}</h4>
            <div className="mb-1" dangerouslySetInnerHTML={{__html: page.summary}}/>
            {page.content && <Link to={page.link}>Lire la suite</Link>}
        </Col>
    );
}