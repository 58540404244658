import {Breadcrumb, Container} from "react-bootstrap";
import {Link} from "react-router-dom";

export default function AppBreadcrumb({breadcrumbItems})
{
    return (
        <div className={`AppBreadcrumb`}>
            {breadcrumbItems.length > 0 ? (
                    <Container className={`breadcrumb`}>
                        <div className={`sub-container`}>
                            <Breadcrumb>
                                <li className={`breadcrumb-item`}>
                                    <Link to={`/`}><i className={`material-icons fs-5 me-1 align-bottom`}>home</i> Accueil </Link>
                                </li>
                                {breadcrumbItems.map(item => (
                                    <li className={`breadcrumb-item`} key={item.link}>
                                        <Link to={item.link}>{item.name}</Link>
                                    </li>
                                ))}
                            </Breadcrumb>
                        </div>
                    </Container>
                ) : (
                    ''
                )
            }
        </div>
    );
}