import React from 'react';
import {useQuery} from "react-query";
import {apiFetchQuery} from "../api";
import Error500 from "./Error500";
import {Col, Container, Row} from "react-bootstrap";

export default function Partner({role, utilities}) {

    const {data, error, isLoading} = useQuery('repoPartnersData', () => apiFetchQuery('get-partners', {}), {
        refetchOnWindowFocus: process.env.NODE_ENV === 'development',
        retry: 1
    });
    if (error) {
        return <Error500/>
    } else {
        console.log(data)
    }
    return (
        !isLoading &&
        <Container>
            <Row>
                {data.map(datas => (
                        <Col md={6} key={datas.id} className={`fw-bold mb-3 g-4`}>
                            <p><span className={`bg-blue p-1`}>{datas.name}</span></p>
                            {datas.partners.map(partner => (
                                <div key={partner.id}><a href={partner.link}>{partner.name}</a></div>
                            ))}
                        </Col>
                    )
                )}
            </Row>
        </Container>
    )
}


