import React, {useCallback, useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {apiFetchQuery, toolsGetId} from "../api";
import {useEdition} from "../contexts/EditionContext";
import {useQuery} from "react-query";
import Error500 from "./Error500";
import Error404 from "./Error404";
import Fancybox from "../components/Fancybox";
import {Col, Container, Image, Row} from "react-bootstrap";
import {Skeleton} from "@mui/material";


export default function Portfolio({utilities}) {
    useEffect(() => {
        utilities.setLocation('portfolio');
        utilities.setBreadcrumbItems([
            {'link': '/galeries', name: 'Galeries'},
        ]);
    }, []);


    const $params = useParams();
    const id = toolsGetId($params.id);
    const {contextEditionData} = useEdition();
    const {
        data,
        error,
        isLoading
    } = useQuery(['repoPagePortfolio', [contextEditionData, id]], () => apiFetchQuery('get-portfolio', {
        id: id,
        edition: contextEditionData.currentEdition.id
    }), {
        refetchOnWindowFocus: process.env.NODE_ENV === 'development',
        retry: 1,
        onSuccess: (data) => {
            if (data.id) {
                utilities.setDocumentTitle(data.name);
                utilities.setBreadcrumbItems([
                    {'link': '/galeries', name: "Galeries"},
                    {'link': '/galeries/' + data.id, name: data.name},
                ]);
            }
        }
    });


    if (error) {
        return <Error500 utilities={utilities}/>
    }

    if (!isLoading && data.id === undefined) {
        return <Error404 utilities={utilities}/>;
    }


    return (
        <Container className={``}>
            <div className={`mx-md-5`}>
                <h1 className={`text-center`}>{isLoading ? <Skeleton variant={"text"} width={300}/> : data.name}</h1>
                <Row className={`row-cols-2 row-cols-md-3 g-2`}>
                    {isLoading
                        ? (
                            <>
                                <Col><Skeleton variant={"rectangular"} height={200}/></Col>
                                <Col><Skeleton variant={"rectangular"} height={200}/></Col>
                                <Col><Skeleton variant={"rectangular"} height={200}/></Col>
                                <Col><Skeleton variant={"rectangular"} height={200}/></Col>
                                <Col><Skeleton variant={"rectangular"} height={200}/></Col>
                                <Col><Skeleton variant={"rectangular"} height={200}/></Col>
                            </>
                        ) : (
                            <Fancybox options={{infinite: false}}>
                                {data.images.map((image, index) => (
                                    <Col className={`align-self-center text-center`} key={image.id}>
                                        <a href={data.upload_dir + '/' + image.source}
                                           data-fancybox="gallery"
                                           data-caption={image.title + (image.copyright ? ` (&copy; ` + image.copyright + `)` : ``)}
                                        >
                                            <Image className={`img-preview img-fluid`}
                                                   data-zoom={data.upload_dir + '/' + image.source}
                                                   src={data.upload_dir + '/' + image.source}
                                                   alt={image.title}
                                                   title={`test`}
                                            />
                                        </a>

                                    </Col>

                                ))}
                            </Fancybox>
                        )
                    }
                </Row>
            </div>
        </Container>
    );
}