import {apiFetch, apiFetchQuery} from "../api";
import {Col, Image, Row} from "react-bootstrap";
import {useQuery} from "react-query";
import Error500 from "../pages/Error500";
import {Skeleton} from "@mui/material";

export default function HomeBlockPartner() {
    const {
        data,
        error,
        isLoading
    } = useQuery('repoHookBlocPartner', () => apiFetchQuery('get-pages', {
        'hook': 'home-bloc-3',
        'max-result': 1,
        'sigle-result': 1
    }),{
        refetchOnWindowFocus: process.env.NODE_ENV === 'development',
        retry: 1
    });
    if (error) {
        return <Error500/>
    }

    return (
        <Row className={`mx-md-5 d-none d-md-block`}>
            {isLoading
                ? (
                    <Col>
                        <h3 className="text-uppercase"><Skeleton variant={"text"}/></h3>
                        <Skeleton variant={"rectangular"} height={200}/>
                    </Col>
                ) : (
                    <Col>
                        <h3 className="text-uppercase">{data.name}</h3>
                        {data.logo !== undefined && data.logo != "" && <Image src={data.logo} alt={data.name}/>}
                        <div dangerouslySetInnerHTML={{__html: data.content}}/>
                    </Col>
                )
            }

        </Row>
    )
}
