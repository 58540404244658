import EventsFilter from "./EventsFilter";

function EventListHeader(props) {
    const contextEditionData = props.contextEditionData;
    const data = props.data;
    return (
        <>
            {(contextEditionData.lastEdition !== undefined && contextEditionData.lastEdition.id !== contextEditionData.currentEdition.id) &&
                <h1 className={`text-uppercase`}>Programme {contextEditionData.currentEdition && contextEditionData.currentEdition.id !== contextEditionData.lastEdition.id && contextEditionData.currentEdition.name}</h1>
            }
            <div className={`sub-container`}>
                {data !== null ? (
                    <EventsFilter
                        isYouthCategory={data.isYouthCategory}
                        categories={data.categories}
                        currentCategory={data.currentCategory}
                        dates={data.dates}
                        currentDate={data.currentDate}
                    />
                ) : (
                    <EventsFilter
                        isYouthCategory={0}
                        categories={[]}
                        currentCategory={{'id': null, 'name': `Tout le programme`}}
                        dates={[]}
                        currentDate={{'id': null, 'name': `Toutes les dates`}}
                    />
                )}

                {data !== null && data.currentCategory && data.currentCategory.description && (
                    <div
                        className={`mt-4 fs-16 ` + (data.currentCategory.highlighting ? `bg-yellow mx-4 full-width` : ``) + ``}>
                        {data.currentCategory.highlighting &&
                            <h4 className={`text-uppercase`}>{data.currentCategory.name}</h4>}
                        <br/>
                        <div
                            dangerouslySetInnerHTML={{__html: data.currentCategory.description}}/>
                    </div>)}
            </div>
        </>
    );
}

EventListHeader.defaultProps = {
    data: null
}

export default EventListHeader
