import React, {useState, useEffect} from 'react';
import {Navbar, Container, Nav, NavDropdown, Row, Col} from 'react-bootstrap';
import AppNavbarItem from "./AppNavbarItem";
import {apiFetchQuery} from "../api";
import Error500 from "../pages/Error500";
import {useQuery} from "react-query";
import {Skeleton} from "@mui/material";
import {EditionContext, useEdition} from "../contexts/EditionContext";
import {Link, useLocation} from "react-router-dom";
import logo from "../assets/images/logo.svg"
import {useMediaQuery} from "react-responsive";

export default function AppNavbar({utilities}) {
    const isTabletOrMobile = useMediaQuery({maxWidth: 992});

    const [offset, setOffset] = useState(0);
    const effect = () => {
        setOffset(window.pageYOffset);
    }
    const [isNavCollapsed, setIsNavCollapsed] = useState(false);

    const handleNavCollapse = () => {
        if (isTabletOrMobile)
            setIsNavCollapsed(!isNavCollapsed);
    };


    useEffect(() => {
        window.addEventListener('scroll', effect);
    }, []);

    const {contextEditionData} = useEdition();
    const {
        data,
        error,
        isLoading
    } = useQuery(['repoNavBar', contextEditionData], () => apiFetchQuery('get-nav-items', {'edition': (contextEditionData.currentEdition !== undefined ? contextEditionData.currentEdition.id : null)}), {
        refetchOnWindowFocus: process.env.NODE_ENV === 'development',
        retry: 1
    });
    if (error) {
        return <Error500/>

    }

    const scroll = utilities.location === 'home' ? 100 : 0;

    return (
        <Container fluid={isTabletOrMobile} id="nav-container">
            <div className="row mx-md-5">

                <Navbar expanded={isNavCollapsed} fixed="top" expand="lg"
                        className={offset > scroll ? `bg-white` : ``}>
                    <Container>
                        <EditionContext.Consumer>
                            {({contextEditionData, setContextEditionData}) => (
                                <Navbar.Brand
                                    as={Link}
                                    to="/"
                                    className={`ms-md-5`}
                                    onClick={(e) => {
                                        if (contextEditionData.lastEdition !== undefined && contextEditionData.lastEdition.id !== contextEditionData.currentEdition.id) {
                                            setContextEditionData({
                                                currentEdition: contextEditionData.lastEdition,
                                                lastEdition: contextEditionData.lastEdition
                                            });
                                            document.documentElement.scrollTop = 0;
                                        }
                                    }}
                                >
                                    <img src={logo} alt="Festival America" className="logo"/>
                                </Navbar.Brand>
                            )}
                        </EditionContext.Consumer>

                        <Navbar.Toggle onClick={handleNavCollapse} aria-controls="basic-navbar-nav"><i
                            className="material-icons">menu</i></Navbar.Toggle>
                        <Navbar.Collapse id="basic-navbar-nav"
                                         className={`mx-lg-0`}>
                            {isLoading
                                ? (
                                    <Nav className="">
                                        <Skeleton width={100} height={20} className={`mx-3 bg-white`}/>
                                        <Skeleton width={100} height={20} className={`mx-3 bg-white`}/>
                                        <Skeleton width={100} height={20} className={`mx-3 bg-white`}/>
                                        <Skeleton width={100} height={20} className={`mx-3 bg-white`}/>
                                        <Skeleton width={100} height={20} className={`mx-3 bg-white`}/>

                                    </Nav>
                                ) : (
                                    <Nav className="d-flex justify-content-between flex-lg-nowrap">
                                        {data.map(item => {
                                            // <AppNavbarItem onSelect={handleNavCollapse} key={item.key} item={item}/>
                                            if ((item.groups !== undefined && item.groups.length > 0) || (item.items !== undefined && item.items.length > 1)) {
                                                return (
                                                    <NavDropdown title={item.name}
                                                                 className={`basic-nav-dropdown ${item.key}`}
                                                                 align={item.align}
                                                                 key={item.key}>
                                                        {item.groups !== undefined && item.groups.length > 0 &&
                                                            <Row>
                                                                {item.groups.map(group => (
                                                                    <Col key={group.key}>
                                                                        <label
                                                                            className="m-md-1 text-nowrap">{group.name}</label>
                                                                        {group.items.map(item2 => (
                                                                            item2.items !== undefined ? (
                                                                                <div className={``} key={item2.key}>
                                                                                    <NavDropdown title={item2.name}
                                                                                                 className={`basic-nav-dropdown ${item2.key}`}
                                                                                                 align={item2.align}>
                                                                                        {item2.items.map(item3 => (
                                                                                            <NavDropdown.Item
                                                                                                onClick={handleNavCollapse}
                                                                                                key={item3.link}
                                                                                                as={Link}
                                                                                                to={item3.link}>{item3.name}</NavDropdown.Item>
                                                                                        ))}
                                                                                    </NavDropdown>
                                                                                </div>
                                                                            ) : (
                                                                                <NavDropdown.Item
                                                                                    onClick={handleNavCollapse}
                                                                                    key={item2.link}
                                                                                    as={Link}
                                                                                    to={item2.link}>{item2.name}</NavDropdown.Item>
                                                                            )
                                                                        ))}
                                                                    </Col>
                                                                ))}
                                                            </Row>
                                                        }
                                                        {item.items !== undefined && item.items.length > 1 && item.items.map(item => (
                                                            <NavDropdown.Item onClick={handleNavCollapse}
                                                                              key={item.link} as={Link}
                                                                              to={item.link}>{item.name}</NavDropdown.Item>
                                                        ))}

                                                    </NavDropdown>
                                                );
                                            }
                                            if (item.items !== undefined && item.items.length === 1) {
                                                const page = item.items[0];

                                                return (
                                                    <Nav.Link as={Link} onClick={handleNavCollapse} to={page.link}
                                                              className={`basic-nav-dropdown ${item.key}`}
                                                              key={item.key}>{page.name}</Nav.Link>
                                                )
                                            }
                                            return '';
                                        })}
                                    </Nav>
                                )
                            }
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        </Container>
    );

}
