import {Link} from "react-router-dom";

export default function EventList(props) {
    const data = props.data;
    return (
        <div className={`events mt-5`}>

            {/*data.currentCategory.id !== null &&
            <div className="mb-4 fw-bold fs-4">{data.currentCategory.name}</div>*/
            }
            {data.eventsByDates.map(eventsByDate => (
                <div className={`eventsByDate`} key={eventsByDate.date}>
                    <h2 className="text-uppercase mb-4 fw-bold fs-4">{eventsByDate.date}</h2>
                    {eventsByDate.events.map(event => (
                        <div className={`event mb-3 fs-16`} key={event.id}>
                            {data.currentCategory === undefined || data.currentCategory.id === null &&
                                <span
                                    className={`d-inline-block bg-yellow px-2 fs-13 fw-bold mb-2`}>> {event.category?.name}</span>
                            }
                            <h4 className="mb-1">
                                <Link to={event.link}
                                      className="fst-normal">{event.timeRange && event.timeRange + " - "} {event.name}</Link>
                            </h4>
                            {event.animator.length > 0 &&
                                <div className="animator">Animé par {event.animator.name}</div>
                            }
                            {event.authors.length > 0 &&
                                <div className="authors">{event.authors.map((author, index, arr) => (
                                    <span key={author.id}>
                                                {index > 0 && index === (arr.length - 1) && <span> et </span>}
                                        {index > 0 && index < (arr.length - 1) && <span>, </span>}
                                        <Link to={author.link}>{author.name}</Link>
                                            </span>
                                ))}</div>
                            }

                            {event.speakers.length > 0 &&
                                <div className="speakers">{event.speakers.map((speaker, index, arr) => (
                                    <span key={speaker.id}>
                                                    {index > 0 && index === (arr.length - 1) && <span> et </span>}
                                        {index > 0 && index < (arr.length - 1) && <span>, </span>}
                                        <Link to={speaker.link}>{speaker.name}</Link>
                                                </span>
                                ))}</div>
                            }
                            <div className="summary">
                                <Link to={event.link}>
                                    <div
                                        dangerouslySetInnerHTML={{__html: event.summary.replaceAll('hidden-on-list', 'd-none')}}/>
                                </Link>
                            </div>
                            {/* > voir l'événement</Link>*/}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}
