import React, {useEffect, useState} from "react";
import {Container, Row, Col, Image} from "react-bootstrap";
import {Link} from "react-router-dom";
import {toolsSetLocalStorage, apiFetchQuery} from "../api";
import {useQuery} from "react-query";
import Error500 from "../pages/Error500";
import {Skeleton} from "@mui/material";
import {EditionContext, useEdition} from "../contexts/EditionContext";
import {useMediaQuery} from "react-responsive";
import SocialLinks from "./SocialLinks";


export default function Footer() {
    const {contextEditionData, setContextEditionData} = useEdition();
    const isTabletOrMobile = useMediaQuery({ maxWidth: 992 })

    const {data, error, isLoading} = useQuery('repoFooter', () => apiFetchQuery('get-footer-items', {
        role: 'AUT',
        'max-result': 8,
        'chunk': 4
    }), {
        refetchOnWindowFocus: process.env.NODE_ENV === 'development',
        retry: 1
    });
    if (error) {
        return <Error500/>
    }

    return (
        <Container>
            {isLoading
                ? (
                    <Row className={`mx-md-5`}>
                        <Col>
                            <div className={`text-uppercase fw-semi-bold mb-2`}><Skeleton variant={"text"}/></div>
                            <Link to={'#'}><Skeleton variant={"text"}/> </Link>
                            <Link to={'#'}><Skeleton variant={"text"}/> </Link>
                            <Link to={'#'}><Skeleton variant={"text"}/> </Link>
                        </Col>
                        <Col>
                            <div className={`text-uppercase fw-semi-bold mb-2`}><Skeleton variant={"text"}/></div>
                            <Link to={'#'}><Skeleton variant={"text"}/> </Link>
                            <Link to={'#'}><Skeleton variant={"text"}/> </Link>
                            <Link to={'#'}><Skeleton variant={"text"}/> </Link>
                        </Col>
                        <Col>
                            <div className={`text-uppercase fw-semi-bold mb-2`}><Skeleton variant={"text"}/></div>
                            <Link to={'#'}><Skeleton variant={"text"}/> </Link>
                            <Link to={'#'}><Skeleton variant={"text"}/> </Link>
                            <Link to={'#'}><Skeleton variant={"text"}/> </Link>
                        </Col>
                        <Col>
                            <div className={`text-uppercase fw-semi-bold mb-2`}><Skeleton variant={"text"}/></div>
                            <Link to={'#'}><Skeleton variant={"text"}/> </Link>
                            <Link to={'#'}><Skeleton variant={"text"}/> </Link>
                            <Link to={'#'}><Skeleton variant={"text"}/> </Link>
                        </Col>

                    </Row>
                ) : (
                    <EditionContext.Consumer>
                        {({contextEditionData, setContextEditionData}) => (
                            <Row className={`mx-md-5 equal`}>
                                {isTabletOrMobile && (<Col xs={12} className={`mb-3`}><SocialLinks alignment={`text-center`} /></Col>)}
                                <Col>
                                    <div className={`h-100 d-flex align-items-start flex-column`}>
                                        <Link to={`/contenu/26-mentions-legales`}
                                              className={`text-uppercase mb-auto`}><span className={`fw-semi-bold`}>Mentions Légales</span></Link>
                                        {!isTabletOrMobile && (<SocialLinks alignment={`text-left`} />)}
                                    </div>
                                </Col>
                                {data.map(col => (
                                    <Col key={col.key}>
                                        {col.items && col.items.length > 0 &&
                                            <>
                                                <div className={`text-uppercase fw-semi-bold mb-2`}>{col.name}</div>
                                                {col.items.map(item => (
                                                    <Link key={item.link} to={item.link}
                                                          onClick={col.key === 'footer-replay' ? (e) => {
                                                              setContextEditionData({
                                                                  currentEdition: {
                                                                      id: item.id,
                                                                      name: item.name
                                                                  }, lastEdition: contextEditionData.lastEdition
                                                              });
                                                              document.documentElement.scrollTop = 0;
                                                          } : undefined} data-id={item.id}
                                                          data-name={item.name}>{item.name}</Link>
                                                ))}
                                            </>
                                        }
                                    </Col>
                                ))}

                            </Row>
                        )}
                    </EditionContext.Consumer>
                )
            }
        </Container>
    );
}