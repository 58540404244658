import React, {cloneElement, isValidElement, useEffect, useRef, useState} from "react";
import {Col, Row} from "react-bootstrap";

export default function Billetterie({utilities, cmsData, setContainerClass}) {

    const [iframeHeight, setIframeHeight] = useState(`940px`);

    useEffect(() => {
        setContainerClass(``);
    }, []);

    const handleResizeIframe = (e) => {
        //setIframeHeight(e.target.scrollHeight + 'px');
    }

    return (
        <>
            <Row>
                <Col>
                    <div dangerouslySetInnerHTML={{__html: cmsData.content}}></div>
                </Col>
            </Row>
            <iframe
                src={`https://billetterie.wilout.com/b68de0d62cccbfef47698c09da05cf9c/w`}
                onLoad={handleResizeIframe}
                style={{width: '100%', height: iframeHeight, backgroundColor: '#feed01'}}
            />
        </>

    )

}