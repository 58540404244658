import {Container} from "react-bootstrap";
import {useEffect} from "react";

export default function Error500({utilities})
{
    useEffect(() => {
        utilities.setDocumentTitle(`500 : Une erreur s'est produite !`);
        utilities.setLocation('500');
    });

    return (
        <Container>
            <div className={`mx-md-5`}>
                <h1>Oups ! une erreur s'est produite.</h1>
            </div>
        </Container>
    )

}