import {Col, Row, Collapse} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useQuery} from "react-query";
import {apiFetchQuery} from "../api";

export default function Residence({utilities, cmsData}) {

    const {data, error, isLoading} = useQuery('repoPageResidences', () => apiFetchQuery('get-pages', {'override': 'residences', 'sigle-result': 1}), {
        refetchOnWindowFocus: process.env.NODE_ENV === 'development',
        retry: 1,
        onSuccess: (data) => {
            utilities.setBreadcrumbItems([
                {'link': '#', name: data.hook},
                {'link': data.link, name: data.name},
                {'link': cmsData.link, name: cmsData.name, 'isActive': 1},
            ]);
        }
    });


    const [collapseElements, setCollapseElements] = useState([]);
    useEffect(() => {
        const residenceElement = document.getElementById('residence');
        setCollapseElements([...residenceElement.getElementsByClassName('collapse')]);
    }, [])

    const handleClickListener = (e) => {
        let eTarget = e.target;
        if (eTarget.name !== 'a') {
            eTarget = eTarget.closest('a');
        }

        if (eTarget !== null && eTarget.dataset.bsToggle == 'collapse') {
            e.preventDefault();
            
            let aControls = eTarget.getAttribute('aria-controls');
            if (aControls !== null) {
                let collapseElement = document.getElementById(aControls);
                let collapseElementWasOpen = false;
                if (collapseElement.classList.contains('show')) {
                    collapseElementWasOpen = true;
                }
                collapseElements.map((element) => {
                    element.classList.remove('show');
                });

                if (!collapseElementWasOpen) {
                    collapseElement.classList.toggle('show');
                }

            }
        }

    }

    return (
        <>
            <Row>
                <Col>
                    <div id={`residence`} dangerouslySetInnerHTML={{__html: cmsData.content}} onClick={handleClickListener}></div>
                </Col>
            </Row>
        </>
    )
}