import {apiFetch, apiFetchQuery} from "../api";
import PageCard from "./PageCard";
import {Row, Image, Col} from "react-bootstrap";
import {useQuery} from "react-query";
import Error500 from "../pages/Error500";
import SkeletonPageCard from "../skeletons/SkeletonPageCard";
import {Skeleton} from "@mui/material";


export default function HomeBlockNews(props)
{
    const {data, error, isLoading} = useQuery('repoHookBlockNews', () => apiFetchQuery('get-pages', {'hook': 'home-bloc-1'}),{
        refetchOnWindowFocus: process.env.NODE_ENV === 'development',
        retry: 1
    });
    if(error)
    {
        return <Error500 />
    }

    return (
        <div className={`bloc-home-news mx-md-5`}>
                {isLoading
                    ? (
                        <>
                            <h3 className={`text-uppercase`}><Skeleton width={200} /> </h3>
                            <Row className={`gy-4`}>
                                <Col><SkeletonPageCard /></Col>
                                <Col><SkeletonPageCard /></Col>
                                <Col><SkeletonPageCard /></Col>
                            </Row>
                        </>

                    ) : (
                        <>
                            {data.length > 0 && (
                                <>
                                    <h3 className={`text-uppercase`}>Édition 2024 : à la une</h3>
                                    <Row>
                                        {data.map(item => (
                                            <PageCard key={item.id} item={item} />
                                        ))}
                                    </Row>
                                </>
                            )}
                       </>
                    )
                }
        </div>
    )
}