import React, {useEffect, useLayoutEffect, useState} from 'react';
import {BrowserRouter as Router, Link, Route, Routes, useLocation} from "react-router-dom";
import {Alert, Container} from "react-bootstrap";
import {EditionContext} from "./contexts/EditionContext";
import ReactGA from 'react-ga';

import AppNavbar from "./components/AppNavbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Cms from "./pages/Cms";
import Participants from "./pages/Participants";
import Participant from "./pages/Participant";
import Events from "./pages/Events";
import Event from "./pages/Event";
import Place from "./pages/Place";
import Places from "./pages/Places";
import Error404 from "./pages/Error404";
import AppBreadcrumb from "./components/AppBreadcrumb";
import AppBando from "./components/AppBando";
import Portfolios from "./pages/Portfolios";
import Portfolio from "./pages/Portfolio";


const Wrapper = ({children}) => {
    const location = useLocation();
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
}

const TRACKING_ID = 'UA-33645537-1';
ReactGA.initialize(TRACKING_ID
);
ReactGA.pageview(window.location.pathname + window.location.search);

export default function App() {
    const [location, setLocation] = useState('');
    const [breadcrumbItems, setBreadcrumbItems] = useState([]);
    const [documentTitle, setDocumentTitle] = useState('Festival America');

    useEffect(() => {
        document.title = documentTitle;
    }, [documentTitle])

    const utilities = {
        setDocumentTitle: setDocumentTitle,
        location: location,
        setLocation: setLocation,
        setBreadcrumbItems: setBreadcrumbItems,
        breadcrumbItems: breadcrumbItems
    };

    return (
        <Router>
            <Wrapper>
                <div className={`App ${location}`}>
                    <div className={`AppNavbar `}>
                        <AppNavbar utilities={utilities}/>
                    </div>
                    <AppBando utilities={utilities}/>
                    <AppBreadcrumb breadcrumbItems={breadcrumbItems}/>
                    <div className={`AppBody`}>
                        <EditionContext.Consumer>
                            {({contextEditionData, setContextEditionData}) => (
                                <div>
                                    {contextEditionData.lastEdition !== undefined && contextEditionData.lastEdition.id !== contextEditionData.currentEdition.id &&
                                        <Container className={`fixed-bottom p-0`}>
                                            <Alert variant={"custom text-center"}>
                                                <Link to={`/les-auteurs`} onClick={(e) => {
                                                    setContextEditionData({
                                                        currentEdition: contextEditionData.lastEdition,
                                                        lastEdition: contextEditionData.lastEdition
                                                    });
                                                    document.documentElement.scrollTop = 0;
                                                }}>Retour à l'édition {contextEditionData.lastEdition.name} du
                                                   festival</Link>
                                            </Alert>
                                        </Container>
                                    }
                                </div>
                            )}
                        </EditionContext.Consumer>

                        <Routes>
                            <Route path="/" element={<Home utilities={utilities}/>}/>
                            <Route path="/les-auteurs" element={<Participants role={`AUT`} utilities={utilities}/>}/>
                            <Route path="/les-auteurs/:id" element={<Participant role={`AUT`} utilities={utilities}/>}/>
                            <Route path="/les-invites" element={<Participants role={`INT`} utilities={utilities}/>}/>
                            <Route path="/les-invites/:id" element={<Participant role={`INT`} utilities={utilities}/>}/>
                            <Route path="/les-artistes" element={<Participants role={`ART`} utilities={utilities}/>}/>
                            <Route path="/les-artistes/:id"
                                   element={<Participant role={`ART`} utilities={utilities}/>}/>
                            <Route path="/les-evenements"
                                   element={<Events isYouthCategory={0} utilities={utilities}/>}/>
                            <Route path="/les-evenements/:id"
                                   element={<Event isYouthCategory={0} utilities={utilities}/>}/>
                            <Route path="/festival-jeunesse"
                                   element={<Events isYouthCategory={1} utilities={utilities}/>}/>
                            <Route path="/festival-jeunesse/:id"
                                   element={<Event isYouthCategory={1} utilities={utilities}/>}/>
                            <Route path="/les-lieux" element={<Places utilities={utilities}/>}/>
                            <Route path="/les-lieux/:id" element={<Place utilities={utilities}/>}/>
                            <Route path="/galeries" element={<Portfolios utilities={utilities}/>}/>
                            <Route path="/galeries/:id" element={<Portfolio utilities={utilities}/>}/>
                            <Route path="/contenu/:id" element={<Cms utilities={utilities}/>}/>
                            <Route path="*" element={<Error404 utilities={utilities}/>}/>
                        </Routes>
                    </div>
                    <div className={`AppFooter`}>
                        <Footer/>
                    </div>
                </div>
            </Wrapper>
        </Router>
    );
}
