import {Link} from "react-router-dom";
import {Image} from "react-bootstrap";
import {Skeleton} from "@mui/material";

export default function SkeletonParticipantCard(props)
{
    return (
        <div className={`participant`}>
            <div className="fw-bold text-uppercase mb-3"><Skeleton variant={"text"} width={80} className={props.bg}  /></div>
            <div className="image"><Skeleton variant={`rectangular`} height={282} className={props.bg} /> </div>
            <h4 className="mt-3 mb-1"><Skeleton variant={`text`} className={props.bg} /> <Skeleton variant={`text`} className={props.bg} /></h4>
            <div className="fw-semi-bold fst-italic fs-15"><Skeleton variant={`text`} className={props.bg} /></div>
            <div className="fw-light"><Skeleton variant={`text`} className={props.bg} /></div>
        </div>
    );
}