import React, {useEffect, useState} from "react";
import {apiFetchQuery, toolsGetId} from "../api";
import {useLocation, useParams} from "react-router-dom";
import {Col, Container, Row} from "react-bootstrap";
import {useQuery} from "react-query";
import Error500 from "./Error500";
import Error404 from "./Error404";
import {Skeleton} from "@mui/material";
import Contact from "./Contact";
import Places from "./Places";
import Accreditation from "./Accreditation";
import Residences from "./Residences";
import Residence from "./Residence";
import Billetterie from "./Billetterie";
import Partner from "./Partner";
import Dedicaces from "./Dedicaces";

export default function Cms({utilities}) {
    useEffect(() => {
        utilities.setLocation('cms');
        utilities.setBreadcrumbItems([
            {'link': '#', name: 'Contenu'},
        ]);
    }, []);


    const $params = useParams();
    const id = toolsGetId($params.id);

    const [containerClass, setContainerClass] = useState(`sub-container`);

    const {data, error, isLoading} = useQuery(['repoPageCms', id], () => apiFetchQuery('get-pages', {
        id: id,
        'max-result': 1,
        'sigle-result': 1
    }), {
        refetchOnWindowFocus: process.env.NODE_ENV === 'development',
        retry: 1,
        onSuccess: (data) => {
            utilities.setDocumentTitle(data.name);
            utilities.setBreadcrumbItems([
                {'link': '#', name: data.hook},
                {'link': data.link, name: data.name, 'isActive': 1},
            ]);
            if (data.override === `full-width`) {
                setContainerClass(``);
            }
        }
    });
    const {hash} = useLocation();
    const [hashElement, setHashElement] = useState(null);
    useEffect(() => {
        if (hash) {
            setHashElement(document.getElementById(hash.substring(1)));
            if (hashElement) {
                hashElement.scrollIntoView({behavior: 'smooth', block: 'center'});
            }
        }
    }, [hash, hashElement, isLoading]);


    if (error) {
        return <Error500 utilities={utilities}/>
    }

    if (!isLoading && data.id === undefined) {
        return <Error404 utilities={utilities}/>;
    }

    return (
        <Container>
            <div className={containerClass}>

                {isLoading
                    ? (
                        <>
                            <h1><Skeleton variant={"text"}/></h1>
                            <Skeleton variant={"text"}/>
                            <Skeleton variant={"text"}/>
                            <Skeleton variant={"text"}/>
                        </>
                    ) : (
                        <div>
                            <h1 id={data.slug}>{data.name}</h1>
                            {data.override && data.override === 'contact' && (
                                <Contact utilities={utilities} data={data}/>)}

                            {data.override && data.override === 'lieux' && (
                                <Places utilities={utilities} csmData={data}/>)}

                            {data.override && data.override === 'accreditation' && (
                                <Accreditation utilities={utilities} data={data}/>)}

                            {data.override && data.override === 'residences' && (
                                <Residences utilities={utilities} cmsData={data}/>)}

                            {data.override && data.override === 'residence' && (
                                <Residence utilities={utilities} cmsData={data}/>)}

                            {data.override && data.override === 'billetterie' && (
                                <Billetterie utilities={utilities} cmsData={data}
                                             setContainerClass={setContainerClass}/>)}

                            {data.override && data.override === 'partner' && (
                                <Partner utilities={utilities} cmsData={data}/>)}

                            {data.override && data.override === 'dedicaces' && (
                                <Dedicaces utilities={utilities} cmsData={data}/>)}

                            {(data.override === "" || data.override === null || data.override === `full-width`) && (
                                <Row>
                                    <Col>
                                        <div dangerouslySetInnerHTML={{__html: data.content}}></div>
                                    </Col>
                                </Row>
                            )}

                        </div>
                    )
                }
            </div>
        </Container>
    );
}
