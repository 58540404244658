import {Col, Dropdown, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

export default function EventsFilter({isYouthCategory, categories, currentCategory, dates, currentDate}) {
    return (
        <Row>
            <Col>
                <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic" className={`bg-red d-block`}>
                        {currentCategory.name}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {currentCategory.id !== null &&
                            <Dropdown.Item as={Link} to={`?date=` + currentDate.id}>Tout le programme</Dropdown.Item>}
                        {categories.map(categorie => (
                            <Dropdown.Item as={Link}
                                           to={`/` + (parseInt(isYouthCategory) === 1 ? `festival-jeunesse` : `les-evenements`) + `?category=` + categorie.id + `&date=` + currentDate.id}
                                           key={categorie.id}>{categorie.name}</Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
            <Col>
                <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic" className={`bg-dark d-block`}>
                        {currentDate.name}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {currentDate.id !== null &&
                            <Dropdown.Item as={Link} to={`?category=` + currentCategory.id}>Toutes les
                                                                                            dates</Dropdown.Item>}
                        {dates.map(date => (
                            <Dropdown.Item as={Link}
                                           to={`/` + (parseInt(isYouthCategory) === 1 ? `festival-jeunesse` : `les-evenements`) + `?category=` + currentCategory.id + `&date=` + date.id}
                                           key={date.id}>{date.name}</Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
        </Row>
    );
}