import {Col, Container, Row} from "react-bootstrap";
import ParticipantCard from "./ParticipantCard";
import React from "react";
import EventCard from "./EventCard";


export default function EventGrid(props) {
    const data = props.data;
    const contextEditionData = props.contextEditionData;

    let background = '';
    const getRowClasses = () => {
        background = (background === 'bg-yellow' ? 'bg-white' : (background === 'bg-white' ? 'bg-footer' : 'bg-yellow'));
        return background + ` py-5 mb-4`;
    }

    return (
        <div className={`events-grid mt-5`}>
            {data.eventsByDates.map(eventsByDate => (
                <Container key={eventsByDate.date} className={getRowClasses()}>
                    <div className={`mx-md-5`}>
                        {eventsByDate.date &&
                            <div className={`text-center fs-25 fw-bold text-uppercase mb-4`}>
                                {eventsByDate.date} {contextEditionData.currentEdition.name}
                            </div>
                        }
                        <Row className={`gy-3`}>
                            {eventsByDate.events.map(event => (
                                <Col xs={6} sm={4} key={event.id}>
                                    <EventCard event={event}/>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </Container>
            ))}
        </div>
    );

}
