import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {useQuery} from "react-query";
import {Col, Container, Image, Row} from "react-bootstrap";

import {apiFetchQuery, toolsGetId} from "../api";
import PageLoading from "./PageLoading";
import Error500 from "./Error500";
import ParticipantCard from "../components/ParticipantCard";
import Participant from "./Participant";
import EventsFilter from "../components/EventsFilter";


import {useEdition} from "../contexts/EditionContext";
import ModalEventAgenda from "../components/ModalEventAgenda";

export default function Event({isYouthCategory, utilities}) {
    let breadcrumbItem = [
        {'link': '#', name: !isYouthCategory ? 'Programme' : 'Festival jeunesse'}
    ];

    useEffect(() => {
        utilities.setLocation('event')
        utilities.setBreadcrumbItems(breadcrumbItem);
    }, []);

    const {contextEditionData, setContextEditionData} = useEdition();
    const [modalShow, setModalShow] = useState(false);
    const $params = useParams();
    const $id = toolsGetId($params.id)
    const {data, error, isLoading} = useQuery(['repoEventData', $id], () => apiFetchQuery('get-event', {'id': $id}), {
        refetchOnWindowFocus: process.env.NODE_ENV === 'development',
        retry: 1,
        onSuccess: (data) => {
            utilities.setDocumentTitle(data.event.name);
            if (data.event.category) {
                breadcrumbItem.push({'link': data.event.category.link, 'name': data.event.category.name});
            }

            breadcrumbItem.push({'link': data.event.link, 'name': data.event.name});

            utilities.setBreadcrumbItems(breadcrumbItem);

            if (contextEditionData.currentEdition !== undefined && contextEditionData.currentEdition.id !== data.event.edition.id) {
                setContextEditionData({
                    currentEdition: data.event.edition,
                    lastEdition: contextEditionData.lastEdition
                });
            }

        }
    });

    if (isLoading) {
        return <PageLoading rows={1} cols={1}/>
    } else if (error) {
        return <Error500 utilities={utilities}/>
    }

    return (
        <div className={`pageEvent`}>
            <Container>
                <div className={`sub-container`}>
                    {data.event.date &&
                        <h2 className="text-uppercase fs-5 fw-bold mb-2">{data.event.dateWithoutTime} - {data.event.timeRange}</h2>
                    }
                    <h1 className="mb-2">{data.event.name}</h1>
                    {data.event.category && <div className="fs-5 mb-2">{data.event.category.name}</div>}
                    {data.event.place &&
                        <div className={`fs-6 mb-2`}>
                            <Link to={data.event.place.link} className="fw-normal fst-normal">
                                <i className="material-icons align-text-bottom">pin_drop</i> {data.event.place.name}
                            </Link>
                        </div>
                    }

                    {data.event.animator.id !== undefined &&
                        <div className="animator mb-2 fw-bold">Animé par {data.event.animator.name}</div>
                    }
                    {data.event.animator.length === 0 && data.oldData.infos1 !== undefined && data.oldData.infos1 !== "" &&
                        <div className="mb-2" dangerouslySetInnerHTML={{__html: data.oldData.infos1}}/>
                    }
                    {data.event.coAnimator.id !== undefined &&
                        <div className="co-animator mb-2">Co-Animé par {data.event.coAnimator.name}</div>
                    }
                    {data.event.coAnimator.length === 0 && data.oldData.sousTitre !== undefined && data.oldData.sousTitre !== "" &&
                        <div className="co-animator mb-2">Co-Animé par {data.oldData.sousTitre}</div>
                    }
                    {data.event.edition.year < 2018 && data.oldData.infos2 !== undefined && data.oldData.infos2 !== "" &&
                        <div dangerouslySetInnerHTML={{__html: data.oldData.infos2}}/>
                    }
                    {data.event.videoTag !== "" &&
                        <div className="mb-2 pt-2" dangerouslySetInnerHTML={{__html: data.event.videoTag}}/>
                    }
                    <div className="summary pt-2 fs-18" dangerouslySetInnerHTML={{__html: data.event.description}}/>
                    {data.event.addBtnAgenda &&
                        <>
                            <Link to={`#`} className={`fst-italic fw-semi-bold text-dark p-0`}
                                  onClick={() => setModalShow(true)}>
                                Ajouter cet évènement à votre agenda
                            </Link>
                            <ModalEventAgenda events={[data.event]} show={modalShow}
                                              onHide={() => setModalShow(false)}/>
                        </>


                    }
                    {data.event.partenaires.length > 0 &&
                        <div className="text-center py-2">
                            <hr className="border-gray"/>
                            En partenariat avec
                            <div className={`event-partenaires`}>
                                {data.event.partenaires.map(logo => (
                                    <Image src={logo} className="mt-2"/>
                                ))}
                            </div>
                        </div>
                    }
                    <hr className="border-2 border-gray"/>

                    {data.event.authors.length > 0 &&
                        <div className="authors">
                            <div className="fw-semi-bold fs-5 mb-4">En présence de :</div>
                            <div className="row gx-4 gy-4">
                                {data.event.authors.map(author => (
                                    <div className="col-6 col-md-3" key={author.id}>
                                        <ParticipantCard participant={author}/>
                                    </div>
                                ))}
                            </div>
                        </div>
                    }
                    {data.event.speakers.length > 0 &&
                        <div className="spekers">Avec la participation de
                            <div className="row">
                                {data.event.speakers.map(speaker => (
                                    <div className="col-3" key={speaker.id}>
                                        <Participant participant={speaker}/>
                                    </div>
                                ))}
                            </div>
                        </div>
                    }
                    {data.event.speakers.length === 0 && data.event.edition.year >= 2018 && data.oldData.infos2 !== undefined && data.oldData.infos2 !== "" &&
                        <div className="spekers">Avec la participation de
                            <div dangerouslySetInnerHTML={{__html: data.event.description}}/>
                        </div>
                    }
                </div>
            </Container>

            {data.event.relatedEvents.length > 0 &&
                <Container className="bg-yellow py-4">
                    <div className={`mx-md-5`}>
                        <h2 className="text-uppercase text-center fs-20 fw-bold mb-4">Vous aimerez aussi</h2>
                        <Row className="">
                            {data.event.relatedEvents.map(relatedEvent => (
                                <Col className="col-2 col-md-4" key={relatedEvent.id}>
                                    <h3 className="fs-18 mb-0 fw-bold">{relatedEvent.name}</h3>
                                    <Link to={relatedEvent.link}>Voir l'événement ></Link>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </Container>
            }
            <Container className={`mt-6`}>
                <div className={`sub-container`}>
                    <EventsFilter categories={data.categories}
                                  currentCategory={{'id': null, 'name': "Type d'événements"}}
                                  dates={data.dates}
                                  currentDate={{'id': null, 'name': "Toutes les dates"}}
                    />
                </div>
            </Container>


        </div>


    )
}
