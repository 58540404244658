import {Image} from "react-bootstrap";
import facebook from "../assets/images/social-link-fb.jpg";
import instagram from "../assets/images/social-link-ig.png";
import twitter from "../assets/images/social-link-tw.jpg";
import youtube from "../assets/images/social-link-yb.png";
import React from "react";

export default function SocialLinks({alignment})
{
    return (
        <div className={`social-links ` + alignment }>
            <a href={`https://www.facebook.com/FestivalAmerica/`}
               target={`_blank`}
               className={`d-inline`}
            >
                <Image src={facebook} alt={`Facebook`}/>
            </a>
            <a href={`https://www.instagram.com/festivalamerica/`}
               target={`_blank`}
               className={`d-inline ms-4`}
            >
                <Image src={instagram} alt={`Instagram`}/>
            </a>
            <a href={`https://twitter.com/FestivalAmerica`}
               target={`_blank`}
               className={`d-inline ms-4`}
            >
                <Image src={twitter} alt={`Twitter`}/>
            </a>
            <a href={`https://www.youtube.com/channel/UCIfP5K_4iGAAdJynxs5TUYw`}
               target={`_blank`}
               className={`d-inline ms-4`}
            >
                <Image src={youtube} alt={`Youtube`}/>
            </a>

        </div>
    )
}