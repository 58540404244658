import {Button, Col, Image, Row} from "react-bootstrap";
import {useState} from "react";
import {apiFetchQuery} from "../api";
import {Field, Form, Formik} from "formik";
import {Alert, Snackbar} from "@mui/material";

export default function Contact({utilities, data}) {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const handleCloseSnackbar = (event) => {
        setOpenSnackbar(false);
    };
    return (
        <Row>
            <Col sm={6}>
                <div dangerouslySetInnerHTML={{__html: data.content}}></div>
                
                <Formik
                    initialValues={{email: "", "name": "", 'message': ""}}
                    validate={values => {
                        const errors = {};
                        if (!values.email) {
                            errors.email = 'Merci de renseigner votre adresse email.';
                        } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                        ) {
                            errors.email = 'Veuillez saisir une adresse email valide.';
                        }
                        if (!values.name) {
                            errors.name = 'Merci de renseigner votre nom.';
                        }
                        if (!values.message) {
                            errors.message = 'Merci de saisir votre message.';
                        }
                        return errors;
                    }}
                    onSubmit={async (values, {resetForm}) => {
                        apiFetchQuery('send-form-contact', {}, 'POST', values);
                        resetForm();
                        setOpenSnackbar(true);
                    }}
                >
                    {({isSubmitting, values, errors, submitCount}) => (

                        <Form>
                            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                                <Alert onClose={handleCloseSnackbar} severity="success" sx={{width: '100%'}}>
                                    Votre message a bien été envoyé
                                </Alert>
                            </Snackbar>
                            <div className={"form-group form-group-sm mb-3"}>
                                <label
                                    className={submitCount > 0 && errors.name && `shake-horizontal`}>{submitCount > 0 && errors.name ? (
                                    <span className={`text-danger`}>* {errors.name}</span>) : (<>Nom <span
                                    className={`text-danger`}>*</span></>)}</label>
                                <Field type={"text"} name={"name"} className={"form-control"}/>
                            </div>
                            <div className={"form-group form-group-sm mb-3"}>
                                <label
                                    className={submitCount > 0 && errors.email && `shake-horizontal`}>{submitCount > 0 && errors.email ? (
                                    <span className={`text-danger `}>* {errors.email}</span>) : (<>E-mail <span
                                    className={`text-danger`}>*</span></>)}</label>
                                <Field type={"email"} name={"email"} className={"form-control"}/>
                            </div>
                            <div className={"form-group form-group-sm mb-3"}>
                                <label
                                    className={submitCount > 0 && errors.message && `shake-horizontal`}>{submitCount > 0 && errors.message ? (
                                    <span className={`text-danger`}>* {errors.message}</span>) : (<>Message <span
                                    className={`text-danger`}>*</span></>)}</label>
                                <Field as={"textarea"} name={"message"} className={"form-control"}/>
                            </div>
                            <Button type={`submit`} variant=""
                                    className={"btn btn-sm bg-dark rounded-0 border-0"}>Envoyer</Button>
                        </Form>
                    )}
                </Formik>
            </Col>
            <Col sm={6}>
                {data.logo !== undefined && data.logo !== "" && <Image src={data.logo} fluid={1}/>}
            </Col>
        </Row>
    );
}