import React, {useEffect} from "react";
import {Link, useParams} from "react-router-dom";
import {apiFetchQuery, toolsGetId} from "../api";
import {useEdition} from "../contexts/EditionContext";
import {useQuery} from "react-query";
import Error500 from "./Error500";
import Error404 from "./Error404";
import {Col, Container, Image, Row} from "react-bootstrap";
import {Skeleton} from "@mui/material";


export default function Portfolios({utilities}) {
    useEffect(() => {
        utilities.setLocation('protfolio');
        utilities.setBreadcrumbItems([
            {'link': '#', name: 'Galeries photo'},
        ]);
    }, []);


    const {contextEditionData} = useEdition();
    const {
        data,
        error,
        isLoading
    } = useQuery(['repoPagePortfolios', [contextEditionData]], () => apiFetchQuery('get-portfolios', {
        edition: contextEditionData.currentEdition.id
    }), {
        refetchOnWindowFocus: process.env.NODE_ENV === 'development',
        retry: 1,
        onSuccess: (data) => {

        }
    });

    if (error) {
        return <Error500 utilities={utilities}/>
    }

    return (
        <Container className={``}>
            <div className={`mx-md-5`}>
                <h1>Galeries photo</h1>
                <Row className={`row-cols-2 row-cols-md-3 g-5`}>
                    {isLoading
                        ? (
                            <>
                                <Col><Skeleton variant={"rectangular"} height={200}/></Col>
                                <Col><Skeleton variant={"rectangular"} height={200}/></Col>
                                <Col><Skeleton variant={"rectangular"} height={200}/></Col>
                                <Col><Skeleton variant={"rectangular"} height={200}/></Col>
                                <Col><Skeleton variant={"rectangular"} height={200}/></Col>
                            </>


                        ) : (
                            <>
                                {data.portfolios.map(portfolio => (
                                    <Col key={portfolio.id}>
                                        {portfolio.thumbnail &&
                                            <Link to={`/galeries/` + portfolio.id}>
                                                <Image src={data.upload_dir + '/' + portfolio.thumbnail}
                                                       alt={portfolio.name}/>
                                            </Link>
                                        }
                                        <h4 className={`text-center my-3`}>{portfolio.name}</h4>

                                    </Col>
                                ))}
                            </>
                        )
                    }
                </Row>
            </div>

        </Container>
    );
}