import * as React from 'react';
import Table from 'react-bootstrap/Table';
import '../assets/styles/Dedicaces.css'
import {Link} from "react-router-dom";

export default function Dedicaces({utilities, cmsData}) {

    const groupBy = key => array =>
        array.reduce((objectsByKeyValue, obj) => {
            const value = obj[key];
            objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
            return objectsByKeyValue;
        }, {});
    const groupByAuteur = groupBy('auteur');

    let dedicaces = groupByAuteur(cmsData.dedicaces);
    dedicaces = Object.entries(dedicaces);

    return (
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>Auteurs</th>
                <th>Jour</th>
                <th>Horaires</th>
                <th>Lieu</th>
            </tr>
            </thead>
            <tbody>
            {dedicaces.map(data => (
                // TO DO A réécrire
                <tr key={data[0]} aria-rowspan={data[1].length}>
                    <td><Link to={data[1][0]['linkAuthor']}>{data[0]}</Link></td>
                    <td>{data[1].map(data => (
                            <div key={data.id + data.jour}> {data.jour}</div>
                        )
                    )
                    }
                    </td>
                    <td>{data[1].map(data => (
                            <div key={data.id + data.heure} className={`text-nowrap`}> {data.heure}</div>
                        )
                    )
                    }
                    </td>
                    <td>{data[1].map(data => (
                            <div key={data.id + data.heure + data.lieu} className={`text-nowrap`}>
                                {data.lieu}
                            </div>
                        )
                    )
                    }</td>

                </tr>

            ))}
            </tbody>
        </Table>
    );
}

