import React, {useEffect, useState} from "react";
import {toolsGetId, apiFetchQuery} from "../api";
import {Link, resolvePath, useParams} from "react-router-dom";
import {Col, Container, Image, Row} from "react-bootstrap";

import Error500 from "./Error500";

import {useQuery} from "react-query";
import {Skeleton} from "@mui/material";
import ModalEventAgenda from "../components/ModalEventAgenda";

import '../assets/styles/Animation.css';
import {useEdition} from "../contexts/EditionContext";

import facebookLogo from '../assets/images/social-link-fb.jpg';
import instagramLogo from '../assets/images/social-link-ig.png';
import twitterLogo from '../assets/images/social-link-tw.jpg';
import defaultAuthor from '../assets/images/Portrait en attente.jpg'

export default function Participant({role, utilities}) {
    const $params = useParams();
    const id = toolsGetId($params.id);

    useEffect(() => {
        utilities.setLocation('participant');
        utilities.setBreadcrumbItems([
            {'link': '#', 'name': 'Invités'},
            {
                'link': (role === 'AUT' ? '/les-auteurs' : (role === 'INT' ? '/les-invites' : '/les-artistes')),
                'name': (role === 'AUT' ? 'Auteurs d\'Amérique' : (role === 'INT' ? "Auteurs d'Europe" : 'Autres invités'))
            },
        ]);
    }, [role]);

    const [modalShow, setModalShow] = useState(false);
    const [lang, setLang] = useState('fr');
    const [languages, setLanguages] = useState([]);
    const {contextEditionData, setContextEditionData} = useEdition();


    const {
        data,
        error,
        isLoading
    } = useQuery(['repoAuthorDetails', id, lang], () => apiFetchQuery('get-author', {
        role: role,
        'id': id,
        'lang': lang
    }), {
        refetchOnWindowFocus: process.env.NODE_ENV === 'development',
        retry: 1,
        onSuccess: (data) => {
            utilities.setDocumentTitle(data.name);
            utilities.setBreadcrumbItems([
                {'link': '#', 'name': 'Invités'},
                {
                    'link': (role === 'AUT' ? '/les-auteurs' : (role === 'INT' ? '/les-invites' : '/les-artistes')),
                    'name': (role === 'AUT' ? 'Auteurs d\'Amérique' : (role === 'INT' ? "Auteurs d'Europe" : 'Autres invités'))
                },
                {'link': data.link, name: data.name}
            ]);

            let flags = [];
            data.languages.map((lang) => {
                flags.push({code: lang, file: require('../assets/images/flags/' + lang + '.png')});
            });
            setLanguages(flags);

            if (contextEditionData.currentEdition !== undefined && contextEditionData.currentEdition.id !== data.edition.id) {
                setContextEditionData({currentEdition: data.edition, lastEdition: contextEditionData.lastEdition});
            }
        }
    });
    if (error) {
        return <Error500 utilities={utilities}/>
    }


    return (
        <Container>
            {isLoading
                ? (
                    <div className={`sub-container`}>
                        <h1 className="mb-0"><Skeleton variant={"text"} width={400}/></h1>
                        <div className="fw-bold text-uppercase fs-16 mb-3"><Skeleton variant={"text"} width={100}/>
                        </div>
                        <div className="fw-semi-bold fs-20"><Skeleton variant={"text"} width={100}/></div>
                        <div className="fw-medium fs-16">
                            <Skeleton variant={"text"} width={200}/>
                        </div>
                        <div className="bg-footer d-flex mt-4 mb-2">
                            <div className="flex-grow-1 text-center">
                                <div className="d-flex justify-content-center align-items-center h-100 px-1">
                                    <Skeleton variant={"rectangular"} height={300}/>
                                </div>
                            </div>
                            <div>
                                <Skeleton variant={"rectangular"} height={300}/>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={`sub-container`}>
                        <h1 className="mb-0">{data.name}</h1>
                        <div
                            className="fw-bold text-uppercase fs-16 mb-3">{data.country !== undefined && data.country.name}</div>
                        {data.book !== null && <div className="fw-semi-bold fs-20 fst-italic">{data.book.title}</div>}
                        <div className="fw-medium fs-16">
                            {data.book !== null && !data.book.lang.information && (
                                <>
                                    {data.book.distributor}{data.book.distributor && data.book.releaseDate && ` - `}{data.book.releaseDate}
                                    {data.book.translator && (<div>Traduit par {data.book.translator}</div>)}
                                </>
                            )}
                            {data.book !== null && data.book.lang.information &&
                                <div dangerouslySetInnerHTML={{__html: data.book.lang.information}}></div>
                            }

                        </div>
                        <div className="bg-footer d-flex mt-4 mb-2">
                            <div className="flex-grow-1 text-center">
                                <div
                                    className="d-flex justify-content-center align-items-center h-100 px-1 overflow-hidden">
                                    {data.book !== null &&
                                        <Image src={data.book.photo}
                                               fluid={1}
                                               className={`tilt-in-top-1 book-image`}/>
                                    }
                                </div>
                            </div>
                            <div>
                                <Image src={data.photo ? data.photo : defaultAuthor} fluid={1}
                                       className={`author-image`}/>
                            </div>
                        </div>

                        <Row>
                            <Col className="copyright fw-medium fs-13">
                                {data.copyright && <>&copy; {data.copyright}</>}
                            </Col>
                            <Col className={`text-end`}>
                                {languages.length > 1 && (
                                    <>
                                        {languages.map(lang => (
                                            <a href={`#`} key={lang.code} className={`ms-3`} onClick={(e) => {
                                                e.preventDefault();
                                                setLang(lang.code)
                                            }}><Image src={lang.file} style={{width: '32px'}}/></a>
                                        ))}
                                    </>
                                )}
                            </Col>
                        </Row>

                        {data.book !== null &&
                            <div className="mt-4">
                                <div className="summary fs-18 mb-3"
                                     dangerouslySetInnerHTML={{__html: data.book.lang.summary}}></div>
                                <div className="opinion fs-18"
                                     dangerouslySetInnerHTML={{__html: data.book.lang.opinion}}></div>
                            </div>
                        }

                        {(data.linkFacebook !== null || data.linkFacebook !== "" || data.linkInstagram !== null || data.linkInstagram !== "" || data.linkTwitter !== null || data.linkTwitter !== "") &&
                            <div className="mt-4 mb-4">
                            {data.linkFacebook !== null && data.linkFacebook !== "" &&
                                <a href={data.linkFacebook} target={`_blank`}><Image src={facebookLogo}
                                                                                     alt={`Facebook`}/></a>}
                            {data.linkInstagram !== null && data.linkInstagram !== "" &&
                                <a href={data.linkInstagram} target={`_blank`}
                                   className={data.linkFacebook && `ms-3`}><Image
                                    src={instagramLogo} alt={`instagram`}/></a>}
                            {data.linkTwitter !== null && data.linkTwitter !== "" &&
                                <a href={data.linkTwitter} target={`_blank`}
                                   className={(data.linkFacebook || data.linkInstagram) && `ms-3`}><Image
                                    src={twitterLogo} alt={`twitter`}/></a>}
                        </div>}

                        {data.lang.biography !== undefined && data.lang.biography !== null &&
                            <div className={`biography`}>
                                <div className="nav nav-tabs" id="nav-tab-1" role="tablist">
                                    <button className="nav-link nav-item active" id="nav-tab-bio" data-bs-toggle="tab"
                                            data-bs-target="#nav-tab-content-bio" type="button" role="tab"
                                            aria-controls="nav-tab-content-bio" aria-selected="true">Biographie
                                    </button>
                                </div>
                                <div className="tab-content pt-3" id="nav-tabContent-1">
                                    <div className="tab-pane fade show active fs-18" id="nav-tab-content-bio"
                                         role="tabpanel" aria-labelledby="nav-tab-bio">
                                        <div dangerouslySetInnerHTML={{__html: data.lang.biography}}></div>
                                    </div>
                                </div>
                            </div>
                        }
                        {data.events !== undefined && data.events !== null && data.events.length > 0 &&
                            <div className={`events`}>
                                <div className="nav nav-tabs mt-4" id="nav-tab-2" role="tablist">
                                    <button className="nav-link nav-item active" id="nav-tab-festival"
                                            data-bs-toggle="tab" data-bs-target="#nav-tab-content-festival"
                                            type="button" role="tab" aria-controls="nav-tab-content-festival"
                                            aria-selected="true">Son festival
                                    </button>
                                </div>
                                <div className="tab-content pt-3" id="nav-tabContent-1">
                                    <div className="tab-pane fade show active fs-16" id="nav-tab-content-festival"
                                         role="tabpanel" aria-labelledby="nav-tab-festival">
                                        {data.events.map(event => (
                                            <p key={event.id}><Link to={event.link}
                                                                    className="fw-bold fst-normal fs-17 ">{event.date} - {event.name}</Link>
                                            </p>
                                        ))}

                                        {data.addBtnAgenda &&
                                            <>
                                                <Link to={`#`} className={`fst-italic fw-semi-bold text-dark p-0`}
                                                      onClick={() => setModalShow(true)}>
                                                    Ajouter&nbsp;
                                                    {data.events.length === 1 &&
                                                        <span>l'événement <i>{data.events[0].name}</i></span>}
                                                    {data.events.length > 1 && <span>un ou plusieurs événements</span>}
                                                    &nbsp;à votre agenda
                                                </Link>
                                                <ModalEventAgenda events={data.events} show={modalShow}
                                                                  onHide={() => setModalShow(false)}/>
                                            </>


                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        {data.dedications !== undefined && data.dedications !== null && data.dedications.length > 0 &&
                            <div className={`dedications`}>
                                <div className="nav nav-tabs mt-4" id="nav-tab-3" role="tablist">
                                    <button className="nav-link nav-item active" id="nav-tab-dedications"
                                            data-bs-toggle="tab" data-bs-target="#nav-tab-content-dedications"
                                            type="button" role="tab" aria-controls="nav-tab-content-dedications"
                                            aria-selected="true">Dédicaces
                                    </button>
                                </div>
                                <div className="tab-content pt-3" id="nav-tabContent-1">
                                    <div className="tab-pane fade show active fs-16" id="nav-tab-content-dedications"
                                         role="tabpanel" aria-labelledby="nav-tab-dedications">
                                        <table className={`table table-bordered table-borderless`}>
                                            {data.dedications.map(dedication => (
                                                <tr key={dedication.id}>
                                                    <td>{dedication.day}</td>
                                                    <td>{dedication.schedule}</td>
                                                    <td>{dedication.place.name}</td>
                                                </tr>
                                            ))}
                                        </table>

                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                )
            }
            {!isLoading &&
                <div className={`mt-6`}>
                    <div className="row gx-5">
                        {data.book !== null && data.book.shopLink !== null &&
                            <div className="col"><a href={data.book.shopLink} className="btn bg-red" rel="noreferrer"
                                                    target="_blank">Acheter le livre</a></div>
                        }
                        <div className="col">
                            <Link
                                to={role === 'AUT' ? `/les-auteurs` : (role === 'INT' ? `/les-invites` : `/les-artistes`)}
                                className="btn bg-dark">
                                Retour liste des invités
                            </Link>
                        </div>
                    </div>
                </div>
            }
        </Container>
    );
}
