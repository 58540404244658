import {Link} from "react-router-dom";
import {Image} from "react-bootstrap";
import defaultAuthor from '../assets/images/Portrait en attente.jpg'

export default function ParticipantCard(props) {
    const participant = props.participant;
    return (
        <div className={`participant`}>
            <div className="fw-bold text-uppercase mb-3">{participant.country.name}</div>
            <div className="image">
                <Link to={participant.link}>
                    <Image src={participant.photo ? participant.photo : defaultAuthor}
                           fluid={1}
                           className="max-height-258"
                           alt={participant.lastname}/>
                </Link>
            </div>
            <h4 className="mt-3 mb-1">{participant.firstname} {participant.lastname}</h4>
            {participant.book !== null && (
                <>
                    <div className="fw-semi-bold fst-italic fs-15">{participant.book.title}</div>
                    <div
                        className="fw-light">{participant.book.distributor}{participant.book.distributor && participant.book.releaseDate && ' - '}{participant.book.releaseDate} </div>
                </>
            )
            }
        </div>
    );
}